import React, { useMemo } from 'react';
import Head from 'next/head';
import { replaceUnicodes } from ':lib/_i18n';
import { normalizeUrlPath } from ':helpers/string-helpers';
import { LOCALES } from '../locales';

const fallbackImage =
  'https://rippling.imgix.net/images/PreviewImage_Product.png?fm=png';

const DEPLOYMENT_URI =
  process.env.NEXT_PUBLIC_DOMAIN_ORIGIN || 'https://www.rippling.com';

const getLocaleRoutePrefix = (locale) => {
  return locale === LOCALES.EN_US ? '' : `/${locale}`;
};

const getIsI18nNotAvail = ({ pageLocale, pageLocaleDefaulted }) => {
  if (!pageLocale) {
    return false;
  }
  return pageLocaleDefaulted;
};

const getDefaultAlternativePage = ({ pages }) => {
  if (pages.length === 0) {
    return null;
  }

  // US as fallback
  const defaultPage = pages.find(({ locale }) => locale === LOCALES.EN_US);

  if (defaultPage) {
    return defaultPage;
  }

  // Whatever is the first one if US is not available
  return pages[0] ?? null;
};

const HeadComponent = ({
  alternativePages: allAlternativePages = [],
  head,
}) => {
  const { mode = {}, styles = {}, seo = {} } = head;
  const { criticalCSS, missingAtomicCSS } = styles;
  const { image, uri, description, _isIndexed, title, _canonicalUrl } = seo;
  const { isDevelopment, locale: pageLocale = LOCALES.EN_US } = mode;

  const seoImage = image || fallbackImage;

  const shouldIndex = _isIndexed && !isDevelopment;
  const isI18nNotAvail = getIsI18nNotAvail(mode);
  const robotsIndexContent =
    shouldIndex && !isI18nNotAvail ? 'index, follow' : 'noindex, nofollow';
  const botsIndexContent = `${robotsIndexContent}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`;

  const sanitizedCanonical = normalizeUrlPath(_canonicalUrl);
  const localePrefix = getLocaleRoutePrefix(pageLocale);
  const currentPath = sanitizedCanonical
    ? `${localePrefix}${sanitizedCanonical}`
    : `${localePrefix}${uri}`;
  const currentUrl = currentPath ? `${DEPLOYMENT_URI}${currentPath}` : '';

  const { defaultAlternativePage, alternativePages } = useMemo(() => {
    const isPagePublishedToLocale = allAlternativePages.some(({ locale }) => {
      return pageLocale === locale;
    });

    if (isPagePublishedToLocale) {
      const defaultPage = getDefaultAlternativePage({
        pages: allAlternativePages,
      });

      // The page is available in the locale, returns the alternative pages
      return {
        defaultAlternativePage: defaultPage,
        alternativePages: allAlternativePages,
      };
    }

    if (!currentPath) {
      // Shouldn't happen
      return {
        defaultAlternativePage: null,
        alternativePages: [],
      };
    }

    return {
      defaultAlternativePage: { path: currentPath, locale: pageLocale },
      alternativePages: [{ locale: pageLocale, path: currentPath }],
    };
  }, [allAlternativePages, currentPath]);

  return (
    <Head>
      {currentUrl && <link rel="canonical" href={currentUrl} />}
      <title>{replaceUnicodes(title)}</title>
      <meta name="description" content={description} />
      {/* indexing */}
      <meta name="robots" content={robotsIndexContent} />
      <meta name="googlebot" content={botsIndexContent} />
      <meta name="bingbot" content={botsIndexContent} />
      {/* i18n */}
      {alternativePages.map(({ locale, path }) => {
        return (
          <link
            rel="alternate"
            href={`${DEPLOYMENT_URI}${path}`}
            hrefLang={locale.toLowerCase()}
            key={`link-locales-${locale}`}
          />
        );
      })}
      {defaultAlternativePage && (
        <link
          rel="alternate"
          href={`${DEPLOYMENT_URI}${defaultAlternativePage.path}`}
          hrefLang="x-default"
        />
      )}
      {/* og data (i18n) */}
      <meta
        property={'og:locale'}
        // these should be lang_REGION (lowercase + _underscore_ + UPPER)
        content={pageLocale.replace(/-/, '_')}
      />
      {/* og data common */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {currentUrl && <meta property="og:url" content={currentUrl} />}
      <meta property="og:site_name" content="Rippling" />
      <meta property="og:image" content={seoImage} />
      <meta property="og:image:width" content="940" />
      <meta property="og:image:height" content="490" />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={seoImage} />,
      <meta name="twitter:creator" content="@ripplingapp" />
      <meta name="twitter:site" content="@ripplingapp" />
      {typeof window !== 'undefined' &&
      window.location.pathname === '/interactive-tour'
        ? [
            <script src="https://js.storylane.io/js/v1/player_min.js" />,
            <script
              dangerouslySetInnerHTML={{
                __html: `
							function launchStorylaneDemo(){
								new StorylaneEmbed(
									{ dom_id: 'storylane-demo-section', // id of the dom element
										demo_url: 'https://app.storylane.io/demo/ynabww7wzqb1?embed=true'
									});
							}
						`,
              }}
            />,
          ]
        : ''}
      {criticalCSS ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `${criticalCSS}`,
          }}
        />
      ) : (
        ''
      )}
      {missingAtomicCSS ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `${missingAtomicCSS}`,
          }}
        />
      ) : (
        ''
      )}
      {/* Used to fix "rogue referrer problem" https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/ */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              originalLocation: document.location.protocol + '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
            });`,
        }}
      />
      {/* <!-- Qualified (Start) --> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
            (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')`,
        }}
      />
      <script
        async
        src="https://js.qualified.com/qualified.js?token=xiZzNAGkA8hE9UwP"
      />
      {/* <!-- Qualified (End) --> */}
    </Head>
  );
};

export default HeadComponent;
